import React from 'react';
import { notification, Modal } from 'antd';

const logout = () => {
  Promise.all([
    localStorage.removeItem('tulip-user'),
    localStorage.removeItem('tulip-token'),
  ]).then(() => {
    window.location.reload();
  });
};

export default (err) => {
  if (err.response) {
    const { response } = err;
    const { statusCode, body } = response;
    if (statusCode === 422) {
      notification.error({
        message: 'Sorry..',
        description: (
          <ul>
            {body.errors.map((err2) => (
              <li key={err2}>
                {err2.param}
                {' '}
                -
                {' '}
                {err2.msg}
              </li>
            ))}
          </ul>
        ),
        placement: 'bottomRight',
      });
    } else if (statusCode === 401) {
      Modal.error({
        centered: true,
        title: 'Unauthorised!',
        content: 'This account is unauthorised, You need to logout!',
        okText: 'Procced',
        onOk() {
          logout();
        },
      });
    } else if (statusCode === 400) {
      notification.error({
        message: 'Sorry..',
        description: body.msg,
        placement: 'bottomRight',
      });
    } else if (statusCode === 403) {
      notification.warning({
        message: 'Sorry..',
        description: body.msg,
        placement: 'bottomRight',
      });
    }
  } else {
    notification.error({
      message: 'Sorry..',
      description: 'Server Error',
      placement: 'bottomRight',
    });
  }
};
