/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCl8CWEpQwKPuNyGE0e2scdwPU8ROIwk30',
  authDomain: 'realestate-f0560.firebaseapp.com',
  projectId: 'realestate-f0560',
  storageBucket: 'realestate-f0560.appspot.com',
  messagingSenderId: '20595635928',
  appId: '1:20595635928:web:5bdeb2810b6d75f55ec9ad',
  measurementId: 'G-2Y9VXCS8CE',
};

const app = initializeApp(config);
export const authentication = getAuth(app);
